import React, { useState } from 'react';
import { populateLocation, handleSubmit } from './utils';
import styles from './styles';

function ContactForm() {
    const [formData, setFormData] = useState({
        Name: '',
        Phone: '',
        Email: '',
        Location: '',
        Note: ''
    });
    const [tooltipActive, setTooltipActive] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const toggleTooltip = () => {
        setTooltipActive(!tooltipActive);
        document.addEventListener(
            'click',
            (event) => {
                if (!event.target.closest('.tooltip-clickable')) {
                    setTooltipActive(false);
                }
            },
            { once: true }
        );
    };

    const handleFormSubmit = (e) => {
        setLoading(true);
        handleSubmit(e, formData).finally(() => setLoading(false));
    };

    const handlePopulateLocation = () => {
        setLoading(true);
        populateLocation(setFormData, formData, setLoading);
    };

    return (
        <div style={styles.container}>
            <h2>Contact my Dad</h2>
            {loading && (
                <div style={styles.loadingOverlay}>
                    <div style={styles.loadingSpinner}></div>
                    <p>Please wait...</p>
                </div>
            )}
            <form onSubmit={handleFormSubmit}>
                <input
                    type="text"
                    name="Name"
                    placeholder="Your Name"
                    required
                    autoComplete="name"
                    value={formData.Name}
                    onChange={handleInputChange}
                    style={styles.input}
                />
                <input
                    type="tel"
                    name="Phone"
                    placeholder="Your Phone (123-456-7890)"
                    required
                    autoComplete="tel"
                    value={formData.Phone}
                    onChange={handleInputChange}
                    style={styles.input}
                />
                <input
                    type="email"
                    name="Email"
                    placeholder="Your Email"
                    required
                    autoComplete="email"
                    value={formData.Email}
                    onChange={handleInputChange}
                    style={styles.input}
                />
                <div style={styles.flex}>
                    <input
                        type="text"
                        name="Location"
                        placeholder="Your Location"
                        required
                        autoComplete="off"
                        value={formData.Location}
                        onChange={handleInputChange}
                        style={styles.flexGrow}
                    />
                    <button type="button" onClick={handlePopulateLocation} style={styles.populateButton}>
                        Populate
                    </button>
                    <div className="tooltip-clickable" onClick={toggleTooltip} style={styles.tooltipClickable}>
                        <img src="/images/info.png" alt="Info" style={styles.infoIcon} />
                        {tooltipActive && (
                            <span style={{ ...styles.tooltipText, ...(tooltipActive ? styles.tooltipClickableActive : {}) }}>
                                Click to auto-fill your location based on your device's GPS or IP address
                            </span>
                        )}
                    </div>
                </div>
                <textarea
                    name="Note"
                    placeholder="Any notes"
                    required
                    autoComplete="off"
                    value={formData.Note}
                    onChange={handleInputChange}
                    style={styles.input}
                />
                <button type="submit" style={styles.submitButton}>Submit</button>
            </form>
        </div>
    );
}

export default ContactForm;
