import React from 'react';
import styles from './styles';

function PhotoGrid() {
    return (
        <div style={styles.container}>
            <div style={styles.photoGrid}>
                <img src="/images/monkey1.jpg" alt="Monkey" style={styles.photo} />
                <img src="/images/monkey2.jpg" alt="Monkey playing" style={styles.photo} />
                <img src="/images/monkey3.jpg" alt="Monkey relaxing" style={styles.photo} />
            </div>
        </div>
    );
}

export default PhotoGrid;
