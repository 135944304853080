const styles = {
    container: {
        width: '90%',
        maxWidth: '600px',
        margin: '20px auto',
        backgroundColor: '#ffffff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        boxSizing: 'border-box',
        fontFamily: 'Arial, sans-serif',
        position: 'relative'
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box'
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px'
    },
    flexGrow: {
        flexGrow: 1,
        padding: '10px',
        marginBottom: '0',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box'
    },
    populateButton: {
        marginLeft: '5px',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        backgroundColor: '#007bff',
        color: '#fff',
        cursor: 'pointer',
    },
    tooltipClickable: {
        position: 'relative',
        cursor: 'pointer',
        marginLeft: '5px',
    },
    tooltipText: {
        visibility: 'hidden',
        width: '180px',
        backgroundColor: '#000',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '6px',
        padding: '5px 0',
        position: 'absolute',
        zIndex: 100,
        bottom: '125%',
        left: '50%',
        transform: 'translateX(-50%)',
        opacity: 0,
        transition: 'visibility 0s, opacity 0.5s linear'
    },
    tooltipClickableActive: {
        visibility: 'visible',
        opacity: 1
    },
    submitButton: {
        width: '100%',
        padding: '10px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        marginTop: '10px'
    },
    submitButtonHover: {
        backgroundColor: '#0056b3'
    },
    photoGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
        gap: '10px',
        width: '100%',
        boxSizing: 'border-box',
    },
    photo: {
        width: '100%',
        height: 'auto',
        borderRadius: '5px',
        objectFit: 'cover'
    },
    loadingOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000
    },
    loadingSpinner: {
        width: '50px',
        height: '50px',
        border: '8px solid #f3f3f3',
        borderTop: '8px solid #007bff',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite'
    },
    '@keyframes spin': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' }
    }
};

export default styles;
