import React, { useEffect } from 'react';
import ContactForm from './ContactForm';
import PhotoGrid from './PhotoGrid';
import { recordVisit, logEvent } from './utils';
import styles from './styles';

function App() {
    useEffect(() => {
        try {
            recordVisit();
        } catch (error) {
            console.error('Error calling recordVisit:', error);
        }

        // Event listeners
        const handleTyping = (e) => logEvent('typing', { value: e.target.value, timestamp: new Date() });
        const handleCursor = (e) => logEvent('cursor', { x: e.clientX, y: e.clientY, timestamp: new Date() });
        const handleClick = (e) => logEvent('click', { x: e.clientX, y: e.clientY, timestamp: new Date() });

        document.addEventListener('input', handleTyping);
        document.addEventListener('mousemove', handleCursor);
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('input', handleTyping);
            document.removeEventListener('mousemove', handleCursor);
            document.removeEventListener('click', handleClick);
        }
    }, []);

    return (
        <div style={styles.body}>
            <div style={styles.container}>
                <h2>Hello, I'm Monkey</h2>
                <p>
                    I'm shy at first but will respond to treats immediately. I am around 8 years old and up to date on all my shots, and neutered. I'm an indoor cat but like going in the back yard sometimes (supervised). I'm not aggressive but I do have all my claws intact so beware--If I look like I'm scared or threatened, I will use my claws! I generally eat everything. Hard food, wet food, pate and chunks. I will eat it. I love laser pointers and bird/feather toys. I also like finding hidden treats in my treat puzzle. I love my dad and my two adopted sisters.
                </p>
            </div>
            <PhotoGrid />
            <ContactForm />
        </div>
    );
}

export default App;