import axios from 'axios';

export const logEvent = async (eventType, details) => {
    try {
        await axios.post('/log-event', { event_type: eventType, details });
    } catch (error) {
        console.error('Error logging event:', error);
    }
};

export const recordVisit = () => {
    console.log('Attempting to record visit...');
    fetch(`/visit`, { method: 'GET' })
        .then((response) => {
            console.log('Received response for visit recording:', response);
            if (response.ok) {
                console.log('Visit recorded successfully.');
            } else {
                console.error(`Failed to record visit. Status: ${response.status}, StatusText: ${response.statusText}`);
                return response.text().then(text => {
                    console.error(`Response text: ${text}`);
                });
            }
        })
        .catch((error) => console.error('Error recording visit:', error));
};



export const populateLocation = (setFormData, formData, setLoading) => {
    console.log('Attempting to populate location...');
    navigator.geolocation.getCurrentPosition(
        (position) => {
            console.log("Geolocation position received:", position);
            setFormData({
                ...formData,
                Location: `Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`
            });
            setLoading(false);
        },
        (error) => {
            console.log('Geolocation permission declined or failed:', error);
            fetch(`/ip-location`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`Failed to fetch IP location: ${response.statusText}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log("IP location data received:", data);
                    setFormData({
                        ...formData,
                        Location: `${data.city} ${data.regionName} ${data.countryCode} (${data.query})`
                    });
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching IP location:', error);
                    alert('Unable to auto-populate location.');
                    setLoading(false);
                });
        }
    );
};

export const handleSubmit = (e, formData) => {
    e.preventDefault();
    console.log('Submitting form with data:', formData);
    fetch(`/submit`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            Name: formData.Name,
            Phone: formData.Phone,
            Email: formData.Email,
            Location: formData.Location,
            Note: formData.Note
        })
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Failed to submit form: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log('Form submission response received:', data);
        alert('Thank you for your submission and helping Monkey!');
    })
    .catch((error) => {
        console.error('Error submitting form:', error);
        alert('An error occurred while submitting the form.');
    });
};
